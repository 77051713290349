import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DeckIndexContainer from './DeckIndexContainer';
import SessionSettingsContainer from './SessionSettingsContainer';
import DeckEditorContainer from './DeckEditorContainer';
import StudyProfileEditor from "./StudyProfileEditor";
import DeckSharer from "./DeckSharer";
import DeckOrderer from "./DeckOrderer";
import DeckCloner from "./DeckCloner";
import ThiemeUnlocker from "./ThiemeUnlocker";
import RedeemCodeModal from "./RedeemCodeModal";
import DeckMerger from './DeckMerger';
import { Plus, Upload, Settings } from 'react-feather';
import { isCohortUser } from '../../util/cohortUtil';
import keyContainsModifier from '../../util/keyContainsModifier';
import { initiateModalExit } from '../../util/modalExit';
import UserStudySettingsEditor from './UserStudySettingsEditor';
import CalendarHeatmap from 'react-calendar-heatmap';
import ProNudgeModal from '../misc/ProNudgeModal';
import SchoolAssigner from './SchoolAssigner';
import DeckMenu from './DeckMenu';
import _ from 'lodash';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTermsOfServiceModal: false,
            showOnboarding: false,
            showDeckEditor: false,
            showDeckMerger: false,
            showSessionSettings: false,
            selectedDeckId: null,
            showDeckMenu: false,
            deckMenuTarget: null,
            editingStudyProfileId: null,
            initializedDebounce: false,
            debounced: null,
            firstTimeCohortUser: false,
            sharingDeckId: null,
            unlockingDeckId: null,
            deckShareFromShortcut: false,
            showDeckOrderer: false,
            firstTimeUser: false,
            showGlobalStudySettings: false,
            showGenericUnlocker: false,
            showNewFeatureTour: false,
            showProNudgeModal: false,
            addDeckMenuIsOpen: false,
            isQuickStart: false,
            cloningDeckId: null,
            parentDeckId: null,
            showSchoolAssigner: null,
            confirmingMenuType: null,
            closeModalAfterInteracting: false,
            lastInputType: null
        }
        this.polls = [];
    }

    componentDidMount() {
        // TODO: This call is really heavy duty + difficult to optimize
        this.props.fetchAllProgress();
        this.props.fetchDecksTree();
        this.props.fetchDecks().then(res => this.setUpPolling());
        this.props.fetchUser().then((res) => {
            this.props.fetchCheckoutSessions();
            // this.checkShowProNudgeModal();
        });
        this.props.fetchHeatmap();
        this.props.setActiveStudyProfile({});
        this.props.clearCards();
        this.props.clearTags();
        this.props.fetchStudyProfiles();
        this.props.fetchUserStudySettings();
        this.props.fetchUserSubscriptions();
        this.props.fetchUserProperties();
        this.checkOnboarding();
        this.determineCohortFirstSeen();
        this.determineUserFirstSession();
        this.logSessionData();

        this.initializedDebounce();

        window.scrollTo(0, 0);

        document.addEventListener('mouseup', this.handleMouseUp);
    }

    componentWillUnmount() {
        this.removeKeyBinds();
        document.body.classList.remove("overflow-hidden");
        document.removeEventListener('mouseup', this.handleMouseUp);
        for (const poll of this.polls) {
            clearInterval(poll);
        }
    }

    handleMouseUp = (e) => {
        const container = document.getElementById('add-deck-menu');
        if (container && !container.contains(e.target)) {
            this.setState({ addDeckMenuIsOpen: false });
        }
    }

    // Check Onboarding State
    checkOnboarding = () => {
        const showOB = (localStorage["showOnboarding"] === 'true');
        if (showOB) {
            const obDeckId = 72814; // 72814 (Prod) // 764 (Local) // 72898 (Staging)
            const config = {
                due: 0,
                new: 2,
                deckId: obDeckId
            };
            this.props.setActiveStudyProfile(config);
            this.props.history.push('/deck/' + obDeckId + '?onboarding=true');
        }
    }

    // Set Home Page Keybinds
    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }
    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }
    keyBinds = (e) => {
        // Escape Keybinds
        if (e.key === "Escape" || e.which === 27 || e.code === "Escape") {
            if (this.state.showDeckEditor) {
                this.hideDeckEditor();
            } else if (this.state.editingStudyProfileId) {
                this.setState({ editingStudyProfileId: null, showSessionSettings: true });
            } else if (this.state.showDeckMerger) {
                this.hideDeckMerger();
            } else if (this.state.sharingDeckId) {
                this.hideDeckSharer();
            }
        }
        // If in an input field, return.
        if (this !== e.target && (/textarea|select|input/i.test(e.target.nodeName) || e.target.type === "text" || e.target.isContentEditable)) { return; }
        if (!this.state.showSessionSettings && !keyContainsModifier(e) && (e.key === "n" || e.which === 78 || e.code === "KeyN")) {
            e.preventDefault();
            this.handleClickCreateDeck();
        } else if ((e.metaKey || e.ctrlKey) && (e.key === '1' || e.which === 49 || e.code === "Digit1")) {
            e.preventDefault();
            if (this.props.decksTree[0]?.id) {
                this.quickStart(this.props.decksTree[0].id);
            }
        } else if ((e.metaKey || e.ctrlKey) && (e.key === '2' || e.which === 50 || e.code === "Digit2")) {
            e.preventDefault();
            if (this.props.decksTree[1]?.id) {
                this.quickStart(this.props.decksTree[1].id);
            }
        } else if ((e.metaKey || e.ctrlKey) && (e.key === '3' || e.which === 51 || e.code === "Digit3")) {
            e.preventDefault();
            if (this.props.decksTree[2]?.id) {
                this.quickStart(this.props.decksTree[2].id);
            }
        }
    }

    setUpPolling = () => {
        if (!_.isEmpty(this.props.pendingImports)) {
            const decks = Object.values(this.props.decks);
            for (const pendingImport of this.props.pendingImports) {
                const job_id = pendingImport.jid;
                if (decks.find(deck => deck.job_id === job_id)) {
                    this.props.removePendingImport(job_id);
                } else {
                    this.startPolling(job_id);
                }
            }
        }
    }

    startPolling = (job_id) => {
        const poll = setInterval(() => {
            this.props.fetchImportStatus(job_id).then(res => {
                if (res == 'complete' || res == 'failed') clearInterval(poll);
            });
        }, 3000);
        this.polls.push(poll);
    }

    determineUserFirstSession = () => {
        const showGuidedTour = (localStorage["showOnboarding"] == 'true');
        if (showGuidedTour) {
            this.setState({ firstTimeUser: true });
        }
    }

    logSessionData = () => {
        if (!_.isEmpty(this.props.activeStudySession)) {
            if ((this.props.activeStudySession.again + this.props.activeStudySession.wrong + this.props.activeStudySession.right + this.props.activeStudySession.easy) === 0) return;
            let startTime = new Date(this.props.activeStudySession.sessionTime);
            let calculatedSessionTime;
            if (!isNaN(startTime)) {
                let difference = Math.abs(new Date().getTime() - startTime.getTime()) / 1000;
                let hours = Math.floor(difference / 3600);
                let minutes = Math.floor((difference % 3600) / 60);
                let seconds = Math.floor(difference % 60);
                if (hours > 0) {
                    calculatedSessionTime = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
                } else {
                    calculatedSessionTime = (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
                }
            } else {
                calculatedSessionTime = "XX:XX"
            }
            ahoy.track("End Session", {
                deck: this.props.activeStudySession.deck,
                deck_uuid: this.props.activeStudySession.deckUUID,
                type: this.props.activeStudySession.type,
                again: this.props.activeStudySession.again,
                wrong: this.props.activeStudySession.wrong,
                right: this.props.activeStudySession.right,
                easy: this.props.activeStudySession.easy,
                session_time: calculatedSessionTime,
            });
            this.props.createPastStudySession({
                deck: this.props.activeStudySession.deck,
                deck_uuid: this.props.activeStudySession.deckUUID,
                session_type: this.props.activeStudySession.type,
                again: this.props.activeStudySession.again,
                wrong: this.props.activeStudySession.wrong,
                right: this.props.activeStudySession.right,
                easy: this.props.activeStudySession.easy,
                duration: calculatedSessionTime,
            }).then(() => {
                this.props.clearActiveStudySession();
            });
        }
    }

    determineCohortFirstSeen = () => {
        if (isCohortUser(this.props.user) && localStorage.getItem('syn-cohort-first-seen') === null) {
            // Set Local Storage Immediately to Prevent Seeing This Again
            localStorage.setItem('syn-cohort-first-seen', 'true');
            this.setState({ firstTimeCohortUser: true });
        }
    }

    // checkShowProNudgeModal = () => {
    //     if (this.props.user.group !== 'thieme' && this.props.user.subscription_status === 'trialing' && this.props.user.subscription_end_date) {
    //         const now = moment();
    //         const subEndDate = moment(this.props.user.subscription_end_date);
    //         const timeRemaining = subEndDate.diff(now, 'days');
    //         if (timeRemaining <= 2 && timeRemaining >= 0 && (!this.props.userProperties.nudgedSynaptiqPro || this.props.userProperties.nudgedSynaptiqPro !== true)) {
    //             this.showProNudgeModal();
    //             this.props.updateUserProperties({ nudgedSynaptiqPro: true });
    //         }
    //     }
    // }

    handleClickStreak() {
        ahoy.track("Click Streak");
    }

    handleClickCreateDeck = () => {
        this.setState({ showDeckEditor: true, showSessionSettings: false });
        document.body.classList.add("overflow-hidden");
    }

    handleClickDeck = (selectedDeckId) => {
        this.setState({ selectedDeckId, showSessionSettings: true });
    }

    handleClickEditDeck = () => {
        this.setState({ showDeckEditor: true, showSessionSettings: false });
        document.body.classList.add("overflow-hidden");
    }

    showDeckMerger = (closeAfter) => {
        if (closeAfter) {
            this.setState({ showDeckMerger: true, showSessionSettings: false, closeModalAfterInteracting: true });
        } else {
            this.setState({ showDeckMerger: true, showSessionSettings: false, closeModalAfterInteracting: false });
        }
    }

    hideAndMergeDeck = () => {
        this.setState({ showDeckMerger: false, selectedDeckId: null, closeModalAfterInteracting: false });
    }

    hideAndCancelMerge = () => {
        if (this.state.closeModalAfterInteracting) {
            this.setState({ showDeckMerger: false });
        } else {
            this.setState({ showDeckMerger: false, showSessionSettings: true });
        }
    }

    hideOnboarding = () => {
        this.setState({ showOnboarding: false });
    }

    hideDeckEditor = () => {
        this.setState({ showDeckEditor: false, selectedDeckId: null, parentDeckId: null });
        document.body.classList.remove("overflow-hidden");
    }

    hideSessionSettings = () => {
        this.props.clearTags();
        this.setState({ selectedDeckId: null, showSessionSettings: false, isQuickStart: false, confirmingMenuType: null, closeModalAfterInteracting: false });
        document.body.classList.remove("overflow-hidden");
    }

    handleClickImportDeck = () => {
        this.props.history.push('/upload');
    }

    editStudyProfile = (id) => {
        this.setState({ editingStudyProfileId: id, showSessionSettings: false });
    }

    hideStudyProfileEditor = () => {
        initiateModalExit('.modal--edit-study-session', () => { });
        this.setState({ editingStudyProfileId: null, showSessionSettings: true });
    }

    cloneDeck = (id) => {
        this.setState({ cloningDeckId: id, showSessionSettings: false });
    }

    hideCloneDeck = () => {
        initiateModalExit('.modal--edit-study-session', () => { });
        this.setState({ cloningDeckId: null, showSessionSettings: true });
    }

    showGlobalStudySettings = () => {
        this.setState({ showGlobalStudySettings: true, showSessionSettings: false });
    }

    hideGlobalStudySettings = () => {
        this.setState({ showGlobalStudySettings: false });
    }

    showDeckSharer = (id, openRoute) => {
        this.setState({ selectedDeckId: id, sharingDeckId: id, showSessionSettings: false, deckShareFromShortcut: openRoute });
    }

    hideDeckSharer = () => {
        if (this.state.deckShareFromShortcut) {
            initiateModalExit('.modal--share-deck', () => {
                this.setState({ sharingDeckId: null, showSessionSettings: false, deckShareFromShortcut: false, selectedDeckId: null });
                document.body.classList.remove("overflow-hidden");
            });
        } else {
            initiateModalExit('.modal--share-deck', () => { });
            this.setState({ sharingDeckId: null, showSessionSettings: true, deckShareFromShortcut: false });
        }
    }

    showThiemeUnlocker = (deckId) => {
        this.setState({ unlockingDeckId: deckId });
    }

    hideThiemeUnlocker = () => {
        this.setState({ unlockingDeckId: null });
    }

    showGenericUnlocker = () => {
        this.setState({ showGenericUnlocker: true });
    }

    hideGenericUnlocker = () => {
        this.setState({ showGenericUnlocker: false });
    }

    showProNudgeModal = () => {
        this.setState({ showProNudgeModal: true });
    }

    hideProNudgeModal = () => {
        this.setState({ showProNudgeModal: false });
    }

    showSchoolAssigner = (deckId) => {
        this.setState({ showSchoolAssigner: deckId });
    }

    hideSchoolAssigner = () => {
        this.setState({ showSchoolAssigner: null });
    }

    openDeckOrderer = (e) => {
        e.preventDefault();
        this.setState({ showDeckOrderer: true });
    }

    hideDeckOrderer = () => {
        this.setState({ showDeckOrderer: false });
    }

    openAddDeckMenu = () => {
        this.setState({ addDeckMenuIsOpen: true });
    }

    quickStart = (deckId) => {
        ahoy.track("Quick Start Session Click");
        this.setState({ selectedDeckId: deckId, isQuickStart: true, showSessionSettings: true });
    }

    createSubDeck = (deckId) => {
        this.setState({ showDeckEditor: true, parentDeckId: deckId });
    }

    openDeckMenu = (deckId, e) => {
        e.stopPropagation();
        this.setState({ selectedDeckId: deckId, showDeckMenu: true, deckMenuTarget: e.currentTarget, lastInputType: 'click' });
    }

    openDeckMenuKeypress = (deckId, e) => {
        if (e.key === "Enter" || e.which === 13 || e.code === "Enter") { 
            e.stopPropagation();
            this.setState({ selectedDeckId: deckId, showDeckMenu: true, deckMenuTarget: e.currentTarget, lastInputType: 'keypress' });
        }
    }

    hideDeckMenu = () => {
        this.setState({ showDeckMenu: false, deckMenuTarget: null });
    }

    menuOpenConfirmingMenu = (confirmingType) => {
        this.setState({ showSessionSettings: true, confirmingMenuType: confirmingType, closeModalAfterInteracting: true });
    }

    clearSelectedId = () => {
        this.setState({ selectedDeckId: null });
    }

    render() {
        const heatmapValues = this.props.heatmap?.slice(this.props.heatmap?.length - 90);
        const heatmapStart = heatmapValues[0]?.date;
        const heatmapEnd = heatmapValues[heatmapValues.length - 1]?.date;
        return (
            <>
                <div className="layout layout--home">
                    <div className='layout--home__container layout_container'>
                        <div className="layout--home__container__wrapper">
                            <div className="layout--home__container__wrapper_header">
                                <div className='layout--home__container__wrapper_header_left'>
                                    <div className='greeting'>
                                        {this.props.user.first_name ? <h1>Hello, <span>{this.props.user.first_name}</span>.</h1> : <h1>Hello</h1>}
                                        {this.props.user.profile_picture && <img className="profile-picture" src={this.props.user.profile_picture} alt="Profile Picture" />}
                                    </div>
                                    <div className='deck-header'>
                                        <h2>My Decks</h2>
                                        <div className='actions'>
                                            {!Capacitor.isNativePlatform() && <div id="add-deck-menu" className='menu menu--add-deck'>
                                                <button className="menu__button button button--secondary button--mobile-collapse button--small" onClick={this.openAddDeckMenu}><Plus /><span>Add Deck</span></button>
                                                {this.state.addDeckMenuIsOpen && <div className="menu__dropdown">
                                                    <div className="menu__dropdown__item">
                                                        <button style={{ textDecoration: 'none' }} onClick={() => this.handleClickCreateDeck()}>
                                                            <span><Plus />New Deck</span>
                                                            <div className='badge badge--keybind'>N</div>
                                                        </button>
                                                    </div>
                                                    <div className="menu__dropdown__item">
                                                        <button style={{ textDecoration: 'none' }} onClick={() => this.handleClickImportDeck()}>
                                                            <span><Upload />Import Deck</span>
                                                            <div className='badge badge--keybind'>I</div>
                                                        </button>
                                                    </div>
                                                </div>}
                                            </div>}
                                            <button className='button button--secondary button--mobile-collapse button--small' onClick={this.showGlobalStudySettings}><Settings /><span>Study Settings</span></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='layout--home__container__wrapper_header_right'>
                                    <div className='review-heatmap'>
                                        <Link to={'/stats'}>
                                            {!_.isEmpty(heatmapValues) && <CalendarHeatmap
                                                startDate={heatmapStart}
                                                endDate={heatmapEnd}
                                                values={heatmapValues}
                                                classForValue={(value) => {
                                                    if (!value) return 'color-empty';
                                                    let classes = '';
                                                    if (!value || value.count <= 0) {
                                                        classes += 'color-empty';
                                                    }
                                                    else if (!value.future && value.count < 25)
                                                        classes += 'past-scale-1';
                                                    else if (!value.future && value.count < 50)
                                                        classes += 'past-scale-2';
                                                    else if (!value.future && value.count < 75)
                                                        classes += 'past-scale-3';
                                                    else if (!value.future)
                                                        classes += 'past-scale-4';
                                                    else if (value.future && value.count < 25)
                                                        classes += 'future-scale-1';
                                                    else if (value.future && value.count < 50)
                                                        classes += 'future-scale-2';
                                                    else if (value.future && value.count < 75)
                                                        classes += 'future-scale-3';
                                                    else {
                                                        classes += 'future-scale-4';
                                                    }
                                                    return classes;
                                                }}
                                                tooltipDataAttrs={(value) => {
                                                    const givenDate = new Date(value.date);
                                                    givenDate.setDate(givenDate.getDate() + 1);
                                                    const today = new Date();
                                                    givenDate.setHours(0, 0, 0, 0);
                                                    today.setHours(0, 0, 0, 0);
                                                    if (value.count !== null) {
                                                        if (!value.future) {
                                                            if (givenDate.getTime() === today.getTime()) {
                                                                return {
                                                                    'data-tip': 'Today<br>Reviews: ' + value.count + '<br> ' + new Date(value.date).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' }),
                                                                    'data-html': true
                                                                }
                                                            } else {
                                                                return {
                                                                    'data-tip': 'Reviews: ' + value.count + '<br> ' + new Date(value.date).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' }),
                                                                    'data-html': true
                                                                }
                                                            }
                                                        } else {
                                                            return {
                                                                'data-tip': 'Reviews Due: ' + value.count + '<br> ' + new Date(value.date).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' }),
                                                                'data-html': true
                                                            }
                                                        }
                                                    }
                                                }}
                                                transformDayElement={(element, value, index) => {
                                                    const givenDate = new Date(value.date);
                                                    givenDate.setDate(givenDate.getDate() + 1);
                                                    const today = new Date();
                                                    givenDate.setHours(0, 0, 0, 0);
                                                    today.setHours(0, 0, 0, 0);
                                                    if (givenDate.getTime() === today.getTime()) {
                                                        return React.cloneElement(element, {
                                                            rx: "50%",
                                                            ry: "50%"
                                                        });
                                                    }
                                                    return element;
                                                }}
                                            />}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={`layout--home__container__wrapper__decks ${this.state.firstTimeCohortUser ? 'first-time-cohort' : ''} ${this.state.firstTimeUser ? 'first-time-user' : ''}`}>
                                <DeckIndexContainer
                                    user={this.props.user}
                                    deckId={this.state.selectedDeckId}
                                    handleClickCreateDeck={this.handleClickCreateDeck}
                                    handleClickDeck={this.handleClickDeck}
                                    showThiemeUnlocker={this.showThiemeUnlocker}
                                    openDeckOrderer={this.openDeckOrderer}
                                    showGenericUnlocker={this.showGenericUnlocker}
                                    quickStart={this.quickStart}
                                    createSubDeck={this.createSubDeck}
                                    openDeckMenu={this.openDeckMenu}
                                    openDeckMenuKeypress={this.openDeckMenuKeypress}
                                />
                                {this.state.showDeckMenu && <DeckMenu
                                    deckId={this.state.selectedDeckId}
                                    deckMenuTarget={this.state.deckMenuTarget}
                                    user={this.props.user}
                                    lastInputType={this.state.lastInputType}
                                    hide={this.hideDeckMenu}
                                    clearSelectedId={this.clearSelectedId}
                                    menuEditDeck={this.handleClickCreateDeck}
                                    menuMergeDeck={this.showDeckMerger}
                                    menuOpenConfirmingMenu={this.menuOpenConfirmingMenu}
                                    menuCloneDeck={this.cloneDeck}
                                    menuShareDeck={this.showDeckSharer}
                                    menuShowSchoolAssigner={this.showSchoolAssigner}
                                />}
                            </div>
                        </div>
                    </div>
                    {/* {this.state.showOnboarding && <OnboardingContainer hideOnboarding={this.hideOnboarding} />} */}
                    {this.state.showDeckEditor &&
                        <DeckEditorContainer
                            deckId={this.state.selectedDeckId}
                            hide={this.hideDeckEditor}
                            createDeck={this.props.createDeck}
                            updateDeck={this.props.updateDeck}
                            parentDeckId={this.state.parentDeckId}
                        />
                    }
                    {this.state.showDeckMerger &&
                        <DeckMerger
                            decks={this.props.decks}
                            deck={this.props.decks[this.state.selectedDeckId]}
                            deckId={this.state.selectedDeckId}
                            hideAndMergeDeck={this.hideAndMergeDeck}
                            hideAndCancelMerge={this.hideAndCancelMerge}
                        />
                    }
                    {this.state.showSessionSettings &&
                        <SessionSettingsContainer
                            deckId={this.state.selectedDeckId}
                            hideModal={this.hideSessionSettings}
                            handleEditClick={this.handleClickEditDeck}
                            handleMergeClick={this.showDeckMerger}
                            studyProfiles={this.props.studyProfilesArr}
                            editStudyProfile={this.editStudyProfile}
                            showDeckSharer={this.showDeckSharer}
                            firstTimeUser={this.state.firstTimeUser}
                            isQuickStart={this.state.isQuickStart}
                            cloneDeck={this.cloneDeck}
                            showSchoolAssigner={this.showSchoolAssigner}
                            confirmingMenuType={this.state.confirmingMenuType}
                            closeAfterConfirming={this.state.closeModalAfterInteracting}
                        />
                    }
                    {this.state.editingStudyProfileId &&
                        <StudyProfileEditor
                            studyProfile={this.props.studyProfiles[this.state.editingStudyProfileId]}
                            hide={this.hideStudyProfileEditor}
                        />
                    }
                    {this.state.cloningDeckId &&
                        <DeckCloner
                            hide={this.hideCloneDeck}
                            deckId={this.state.cloningDeckId}
                            tags={this.props.decks[this.state.cloningDeckId]?.tags}
                        />
                    }
                    {this.state.sharingDeckId &&
                        <DeckSharer
                            deckShareOpenRoute={this.state.deckShareOpenRoute}
                            deck={this.props.decks[this.state.sharingDeckId]}
                            hide={this.hideDeckSharer}
                        />
                    }
                    {this.state.unlockingDeckId &&
                        <ThiemeUnlocker
                            deck={this.props.decks[this.state.unlockingDeckId]}
                            hide={this.hideThiemeUnlocker}
                        />
                    }
                    {this.state.showGenericUnlocker &&
                        <RedeemCodeModal
                            hide={this.hideGenericUnlocker}
                        />
                    }
                    {this.state.showProNudgeModal &&
                        <ProNudgeModal
                            hide={this.hideProNudgeModal}
                        />
                    }
                    {this.state.showDeckOrderer &&
                        <DeckOrderer
                            hide={this.hideDeckOrderer}
                        />
                    }
                    {this.state.showGlobalStudySettings &&
                        <UserStudySettingsEditor
                            hide={this.hideGlobalStudySettings}
                        />
                    }
                    {this.state.showSchoolAssigner &&
                        <SchoolAssigner
                            deck={this.props.decks[this.state.showSchoolAssigner]}
                            hide={this.hideSchoolAssigner}
                        />
                    }
                </div>
            </>
        )
    }
}