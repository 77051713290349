import _ from 'lodash';
import React, { Component } from 'react';
import LogoCircle from '../../../assets/images/logo-circle.svg';
import DotGrid from '../../../assets/images/dot-grid.svg';

export default class Onboarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exitting: false,
            exitted: false
        }
        this.timeoutIds = [];
    }

    componentDidMount() {
        document.body.classList.add('overflow-hidden');
        setTimeout(() => {
            let children = document.getElementById('onboarding-frame-0').children;
            for (var i = 0; i < children.length; i++) {
                this.delayRender(children[i], i);
            }
            setTimeout(() => {
                this.setState({exitting: true}, () => {
                    setTimeout(() => {
                        this.setState({exitted: true});
                    }, 750);
                });
            }, children.length * 1750);
        });
    }

    componentWillUnmount() {
        document.body.classList.remove('overflow-hidden');
        this.timeoutIds.forEach(id => clearTimeout(id));
    }

    delayRender = (child, delay) => {
        const id = setTimeout(() => {
            child.classList.add('enter');
        }, 1750 * delay);
        this.timeoutIds.push(id);
    }

    render() {
        return (
            <div className={`onboarding ${this.state.exitting ? 'fade-out' : ''} ${this.state.exitted ? 'hide' : ''}`}>
                <img className='dot-grid' src={DotGrid} alt=""/>
                <div className='onboarding_content'>
                    <div className='logo'>
                        <img src={LogoCircle} alt="Synaptiq Logo"/>
                    </div>
                    <div id='onboarding-frame-0' className='onboarding_content_frame'>
                        {/* <h1><span>Hello</span> { this.props.name }</h1> */}
                        <p className='m-t-1'>Welcome to Synaptiq<span className="m-l-0-50" role="img" aria-label="waving hand">👋</span></p>
                        <p>Let's level up your learning...</p>
                    </div>
                </div>
                <img className='dot-grid dot-grid-2' src={DotGrid} alt=""/>
            </div>
        );
    }
}
