import React, { Component } from 'react';
import Z from '../../../assets/images/icons/key-z.svg';
import Z_Dark from '../../../assets/images/icons/z-dark.svg';
import C from '../../../assets/images/icons/key-c.svg';
import C_Dark from '../../../assets/images/icons/c-dark.svg';
import E from '../../../assets/images/icons/key-e.svg';
import E_Dark from '../../../assets/images/icons/e-dark.svg';
import F from '../../../assets/images/icons/key-f.svg';
import F_Dark from '../../../assets/images/icons/key-f-dark.svg';
import S from '../../../assets/images/icons/key-s.svg';
import S_Dark from '../../../assets/images/icons/s-dark.svg';
import I from '../../../assets/images/icons/key-i.svg';
import I_Dark from '../../../assets/images/icons/key-i-dark.svg';
import R from '../../../assets/images/icons/key-r.svg';
import R_Dark from '../../../assets/images/icons/r-dark.svg';
import DELETE_KEY from '../../../assets/images/icons/key-delete.svg';
import DELETE_KEY_DARK from '../../../assets/images/icons/dark/key-delete.svg';
import { connect } from 'react-redux';
import { RefreshCcw, Eye, RotateCcw, Star, Edit, Copy, EyeOff, Info, Flag } from 'react-feather';
import ToastStar from '../misc/ToastStar';
import ToastUnstar from '../misc/ToastUnstar';
class CardMenu extends Component {
    constructor(props) {
        super(props);
    }

    isStarred = () => {
        const tag_list = this.props.card.tag_list;
        if (!Array.isArray(tag_list)) return false;
        return tag_list.includes('Starred');
    }

    tagCard = () => {
        this.props.tagCard(this.props.card.id, 'Starred');
        toast(<ToastStar/>, {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    untagCard = () => {
        this.props.untagCard(this.props.card.id, 'Starred');
        toast(<ToastUnstar/>, {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    render() {
        let undoIcon = <RefreshCcw/>;
        return (
            <React.Fragment>
                <div id="card-menu" className={`menu menu--card ${this.props.isActive ? "is-open" : ""}`} style={{'top': this.props.menuY}}>
                    <div className="menu__dropdown">
                        {this.props.isPrevCard &&
                            <div className="menu__dropdown__item">
                                <button onClick={() => this.props.undoResponse()}>
                                    {undoIcon}
                                    Undo Response<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={Z} alt="Z" /><img className="keybind dark" src={Z_Dark} alt="Z" />
                                </button>
                            </div>
                        }
                        {this.props.isFlipped &&
                            <div className="menu__dropdown__item">
                                <button onClick={() => this.props.unflip()}>
                                    <RotateCcw/>
                                    View Front<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={F} alt="F" /><img className="keybind dark" src={F_Dark} alt="F" />
                                </button>
                            </div>
                        }
                        {this.props.card.editable && !Capacitor.isNativePlatform() && 
                            <div className="menu__dropdown__item">
                                <button onClick={() => this.props.editCard()}>
                                    <Edit />
                                    Edit Card<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={E} alt="E" /><img className="keybind dark" src={E_Dark} alt="E" />
                                </button>
                            </div>
                        }
                        {(this.props.user?.admin || this.props.user?.curator) &&
                            <div className="menu__dropdown__item">
                                <button onClick={() => this.props.cloneCard()}>
                                    <Copy />
                                    Clone Card<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={C} alt="C" /><img className="keybind dark" src={C_Dark} alt="C" />
                                </button>
                            </div>
                        }
                        {/* <div className="menu__dropdown__item">
                            <button onClick={() => this.props.suspendCard()}>
                                <EyeOff />
                                Suspend Card<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={DELETE_KEY} alt="Delete" /><img className="keybind dark" src={DELETE_KEY_DARK} alt="Delete" />
                            </button>
                        </div> */}
                        {this.props.card.editable && !this.isStarred() && 
                            <div className="menu__dropdown__item is-not-starred">
                                <button onClick={this.tagCard}>
                                    <Star/>
                                    Star<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={S} alt="S" /><img className="keybind dark" src={S_Dark} alt="Ctrl S" />
                                </button>
                            </div>
                        }
                        {this.props.card.editable && this.isStarred() && 
                            <div className="menu__dropdown__item is-starred">
                                <button onClick={this.untagCard}>
                                    <Star/>
                                    Un-Star<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={S} alt="S" /><img className="keybind dark" src={S_Dark} alt="Ctrl S" />
                                </button>
                            </div>
                        }
                        {!this.props.card.editable &&
                            <div className="menu__dropdown__item">
                                <button onClick={() => this.props.showCardReporter()}>
                                    <Flag />
                                    Report Card<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={R} alt="R" /><img className="keybind dark" src={R_Dark} alt="Ctrl R" />
                                </button>
                            </div>
                        }
                        {this.props.card.model !== 'multichoice' && <div className="menu__dropdown__item">
                            <button onClick={() => this.props.showCardDetails()}>
                                <Info/>
                                Card Details<span className='keybind-dash'>&nbsp;&nbsp;&#8211;&nbsp;&nbsp;</span><img className="keybind" src={I} alt="I" /><img className="keybind dark" src={I_Dark} alt="Ctrl I" />
                            </button>
                        </div>}
                        {!Capacitor.isNativePlatform() && <div className="menu__dropdown__item">
                            <button onClick={() => this.props.showShortcutsModal()}>
                                <Eye />
                                View Shortcuts
                            </button>
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CardMenu);
